.PageLoader {
	position: fixed;
	background-color: white;
	height: 100vh;
	width: 100%;
  z-index: 99999999;
  opacity: 1;
  visibility: visible;
}

.hidden {
  visibility: hidden;
  opacity: 0;
  transition: visibility 0.3s, opacity 0.3s linear;
}
