
// Do not edit directly
// Generated on Wed, 28 Jul 2021 14:24:57 GMT

$color-brand-grullo: #1890ff;
$color-brand-jet: #3d3b39;
$color-brand-spanishgreen: #018e42;
$color-brand-redncs: #bf1a2f;
$color-brand-white: #ffffff;
$color-brand-gray-100: #f8f9fa;
$color-brand-gray-200: #e9ecef;
$color-brand-gray-300: #dee2e6;
$color-brand-gray-400: #ced4da;
$color-brand-gray-500: #adb5bd;
$color-brand-gray-600: #6c757d;
$color-brand-gray-700: #495057;
$color-brand-gray-800: #343a40;
$color-brand-gray-900: #212529;
$color-brand-black: #000000;
$color-ui-primary: $color-brand-grullo;
$color-ui-secondary: $color-brand-jet;
$color-ui-success: $color-brand-spanishgreen;
$color-ui-danger: $color-brand-redncs;
$color-ui-white: $color-brand-white;
$color-ui-gray-100: $color-brand-gray-100;
$color-ui-gray-200: $color-brand-gray-200;
$color-ui-gray-300: $color-brand-gray-300;
$color-ui-gray-400: $color-brand-gray-400;
$color-ui-gray-500: $color-brand-gray-500;
$color-ui-gray-600: $color-brand-gray-600;
$color-ui-gray-700: $color-brand-gray-700;
$color-ui-gray-800: $color-brand-gray-800;
$color-ui-gray-900: $color-brand-gray-900;
$color-ui-black: $color-brand-black;