/*
----- Typography
*/

// Alignment

.left {
	text-align: left;
}

.center {
	text-align: center;
}

.right {
	text-align: right !important;
}

/*
----- Layout
*/

// Sizing

.vh-100 {
	min-height: 100vh;
}

