@import '../../assets/scss/import';

.Header {
	background-color: #fff;
}

.left {
    text-align: left;
}

.right {
    display: flex;
    justify-content: flex-end;
}

.iconGroup {
    margin-right: 24px;
    .icon {
        cursor: pointer;
        color: $color-primary;
        font-size: 1.1rem;
    }
    *:hover {
        color: $color-primary;
    }
}

.userDetails {
    * {
        cursor: pointer;
        &:hover {
            color: $color-primary;
            opacity: 0.95;
        }
    }
}
