@import './variables';

/*
----- Pages
*/

@mixin page {
    .pageBody {

    }

    .pageHeader {
        
    }
}

@mixin tableLink {
    .tableLink {
        cursor: pointer;
        &:hover {
            color: $color-primary
        }
    }
}