@import '../../assets/scss/import';

@mixin transition {
	transition: margin-left 150ms;
}

.App {
	.page {
		background: #fff;
	}

	.siteLayoutLargeMargin {
		margin-left: 240px;
		@include transition;
	}

	.siteLayoutSmallMargin {
		margin-left: 80px;
		@include transition;
	}

	.siteContainer {
		min-height: 100vh !important;
	}

	.content {
		margin: 0 16px;
	}

	.header {
		padding: 0;
	}

	.footer {
		text-align: center;
	}
}



