@import '../../assets/scss/import';

.Sider {
	overflow: auto;
	height: 100vh;
	position: fixed;
	left: 0;
}

.logoContainer {
	display: flex;
	height: 32px;
	margin: 16px 16px 16px 20px;
	margin-bottom: 27px;
}

.logoImg {
	height: 30px;
}

.logoText {
	padding-top: 3px;
	padding-left: 20px;
	width: 90%;
}

.menu {
	padding-top: 0px;
}
